import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: inline-flex;\n  justify-content: center;\n  cursor: pointer;\n  border: 0;\n  outline: 0;\n  flex-grow: 1;\n  padding: ",
        ";\n  border-radius: ",
        ";\n  font-size: 16px;\n  font-weight: 600;\n\n  ",
        " {\n    flex-grow: 0;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { color } from "styled-system";
var getBorderRadius = function(param) {
    var scale = param.scale;
    return scale === "md" ? "16px 16px 0 0" : "24px 24px 0 0";
};
var getPadding = function(param) {
    var scale = param.scale;
    return scale === "md" ? "8px" : "16px";
};
var Tab = styled.button.withConfig({
    componentId: "sc-e10c0dd0-0"
})(_templateObject(), getPadding, getBorderRadius, function(param) {
    var theme = param.theme;
    return theme.mediaQueries.md;
}, color);
Tab.defaultProps = {
    scale: "md"
};
export default Tab;
