import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { TwitterIcon, InstagramIcon, GithubIcon, DiscordIcon } from "../Svg";
export var footerLinks = [
    {
        label: "About",
        items: [
            {
                label: "Contact",
                href: "https://docs.pancakeswap.finance/contact-us"
            },
            {
                label: "Blog",
                href: "https://medium.com/pancakeswap"
            },
            {
                label: "Community",
                href: "https://docs.pancakeswap.finance/contact-us/telegram"
            },
            {
                label: "CAKE",
                href: "https://docs.pancakeswap.finance/tokenomics/cake"
            },
            {
                label: "—"
            },
            {
                label: "Online Store",
                href: "https://pancakeswap.creator-spring.com/",
                isHighlighted: true
            }, 
        ]
    },
    {
        label: "Help",
        items: [
            {
                label: "Customer",
                href: "Support https://docs.pancakeswap.finance/contact-us/customer-support"
            },
            {
                label: "Troubleshooting",
                href: "https://docs.pancakeswap.finance/help/troubleshooting"
            },
            {
                label: "Guides",
                href: "https://docs.pancakeswap.finance/get-started"
            }, 
        ]
    },
    {
        label: "Developers",
        items: [
            {
                label: "Github",
                href: "https://github.com/pancakeswap"
            },
            {
                label: "Documentation",
                href: "https://docs.pancakeswap.finance"
            },
            {
                label: "Bug Bounty",
                href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty"
            },
            {
                label: "Audits",
                href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited"
            },
            {
                label: "Careers",
                href: "https://docs.pancakeswap.finance/hiring/become-a-chef"
            }, 
        ]
    }, 
];
export var socials = [
    {
        label: "Instagram",
        icon: InstagramIcon,
        href: "#"
    },
    {
        label: "Twitter",
        icon: TwitterIcon,
        href: "#"
    },
    // {
    //   label: "Telegram",
    //   icon: TelegramIcon,
    // items: [
    //   // {
    //   //   label: "English",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Bahasa Indonesia",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "中文",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Tiếng Việt",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Italiano",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "русский",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Türkiye",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Português",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Español",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "日本語",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Français",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Deutsch",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Filipino",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "ქართული ენა",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "हिन्दी",
    //   //   href: "#",
    //   // },
    //   // {
    //   //   label: "Announcements",
    //   //   href: "#",
    //   // },
    // ],
    // },
    // {
    //   label: "Reddit",
    //   icon: RedditIcon,
    //   href: "#",
    // },
    {
        label: "Github",
        icon: GithubIcon,
        href: "#"
    },
    {
        label: "Discord",
        icon: DiscordIcon,
        href: "#"
    }, 
];
export var langs = _to_consumable_array(Array(20)).map(function(_, i) {
    return {
        code: "en".concat(i),
        language: "English".concat(i),
        locale: "Locale".concat(i)
    };
});
